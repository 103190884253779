@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.App {
  text-align: center;
}

html {
  overflow: hidden;
}

body {
  font-family: "Poppins";
}

#portal {
  position: fixed;
  z-index: 1000;
}

.text-oneline,
.text-twoline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
}

.text-oneline {
  -webkit-line-clamp: 1;
}
.text-twoline {
  -webkit-line-clamp: 2;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-layout {
  background: linear-gradient(
    117.83deg,
    #f3fbff 10.85%,
    #b3dcf9 96.06%
  ) !important;
}

.ant-menu {
  background: #fff !important;
}

.ant-menu > .ant-menu-item {
  height: 72px;
  margin: 0;
  line-height: initial;
  padding: 0;
  display: flex;
  align-items: center;
  border-left: 3px solid transparent !important;
  border-right: 3px solid transparent !important;
}

.ant-dropdown-menu {
  overflow-y: auto;
  width: 284px;
  height: auto;
  max-height: 260px;
  top: 12px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px !important;
}

.goal_checkbox .ant-dropdown-menu {
  width: 192px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goal_checkbox .ant-dropdown-menu-item {
  color: #a3a3a3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row_edit .ant-dropdown-menu {
  width: 180px;
  height: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  top: 0px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0;
}

.row_edit .ant-dropdown-menu-item:nth-child(1) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: red;
  padding: 12px 10px;
  width: 100%;
  background-color: transparent;
}
.row_edit .ant-dropdown-menu-item:nth-child(1) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 12px 10px;
  width: 100%;
  background-color: transparent;
}
.row_edit .ant-dropdown-menu-item:nth-child(1).ant-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
.mission_row_edit .ant-dropdown-menu {
  width: 180px;
  height: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mission_row_edit .ant-dropdown-menu-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  background-color: transparent;
  color: #1589ca;
}
.mission_row_edit .ant-dropdown-menu-item:last-child {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: red;
  width: 100%;
  background-color: #ececec;
}

.mission_row_edit .ant-dropdown-menu-title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actionplan_dropdown .ant-dropdown-menu {
  width: 100%;
  height: auto;
  max-height: 260px;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow-y: auto;
}

.header_dropdown .ant-dropdown-menu {
  width: 180px;
  height: initial;
}
.ant-checkbox-inner {
  width: 1.25rem;
  height: 1.25rem;
}

.ant-dropdown-menu::-webkit-scrollbar {
  width: 7px;
}
.ant-dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ca717800;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: #ffffff;
}
.ant-dropdown-menu::-webkit-scrollbar-thumb {
  background: #b7babb;
  border-radius: 10px;
}
.ant-dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #b7babb;
}
.ant-dropdown-menu {
  scroll-behavior: smooth;
}
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  background-color: #1271a6 !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding-left: 0%;
  padding-right: 0%;
}

.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a > span {
  color: #fff !important;
}

.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a > img {
  filter: brightness(0) invert(1) !important;
}

.ant-menu > .ant-menu-item-open svg path,
.ant-menu > .ant-menu-submenu-open svg path,
.ant-menu > .ant-menu-item-selected svg path,
.ant-menu > .ant-menu-submenu-selected svg path {
  fill: #1890ff;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: inline-block;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1689ca;
  border-color: #1689ca;
}

.ant-checkbox-inner::after {
  margin-left: 2px;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 0% !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0% !important;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  opacity: 0 !important;
}

.ant-select-selector {
  border-radius: 30px !important;
  border-color: #fff !important;
  border: none !important;
  background-color: #fff !important;
}

.ant-select-open {
  border-radius: 30px !important;

  /* background-color: #5D83AB !important; */
}

/* .ant-select-open > div > .ant-select-selection-placeholder {
  color: #fff !important;
} */
.mission_dropdown.ant-select.ant-select-single {
  border: 1px solid #e3e5e9;
  border-radius: 0px !important;
  max-width: 300px;
  width: 100%;
  /* @media screen and (max-width: 743px) {
    max-width: 175px;
  } */
}

/* OVER-RIDING EXISTING ANT DESIGN SELECT CLASS */
@media screen and (min-width: 1650px) {
  .mission_dropdown.ant-select.ant-select-single {
    max-width: 100%;
  }
}

.ant-select.ant-select-single {
  border: 1px solid #e3e5e9;
  border-radius: 4px !important;
  max-width: 175px;
  width: 100%;
}

.ant-select.ant-select-single .ant-select-selector {
  border-radius: 4px !important;
}

.ant-select.ant-select-single.ant-select-status-error {
  border-color: #f00;
}

/* @media screen and (max-width: 865px) {
  .ant-select.ant-select-single {
    width: 224px;
  }
} */

@media screen and (max-width: 743px) {
  .ant-select.ant-select-single {
    width: 180px;
  }
}
.ant-select-item-option-content {
  white-space: pre-wrap;
  overflow: visible;
}
.ant-select-selector {
  padding: 3px 12px !important;
  cursor: pointer !important;
  height: initial !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-image: url("./assets/images/arrow-down.svg");
}

.ant-select-selection-placeholder {
  font-family: "Inter";
  color: #8e97a3 !important;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ant-select-arrow {
  display: none !important;
}

.ant-select-item {
  /* text-transform: uppercase; */
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #1b2a3d;
  padding: 10px 20px;
}
.ant-select-item:hover {
  background: #ececec;
}

.ant-select-dropdown {
  padding: 8px 0;
  background: #fff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.ant-select-item.ant-select-item-group {
  text-transform: initial;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000;
  padding: 10px 10px 0 10px;
}

.darkSelector > .ant-select-selector > span {
  color: #1271a6 !important;
}

.darkSelector > .ant-select-open > .ant-select-arrow {
  color: #1271a6 !important;
  text-decoration-line: underline;
}

.darkSelector > .ant-select-selection-placeholder {
  color: #1271a6 !important;
  text-decoration-line: underline;
}

.darkSelector > .ant-select-arrow {
  color: #1271a6 !important;
}

.whiteSelector {
  margin-top: 10px;
  margin-bottom: -10px;
}

.whiteSelector > .ant-select-selector > span {
  color: #fff !important;
  font-size: 12px;
}

.whiteSelector > .ant-select-open > .ant-select-arrow {
  color: #fff !important;
  text-decoration-line: underline;
}

.whiteSelector > .ant-select-selection-placeholder {
  color: #fff !important;
  text-decoration-line: underline;
}

.whiteSelector > .ant-select-arrow {
  color: #fff !important;
}

.actionItemCollapse > div > div {
  width: 100%;
}

.actionItemCollapse .ant-collapse-content {
  position: relative;
  z-index: 9;
}

.ant-table-thead .ant-table-cell {
  background-color: #2d8cc1;
  color: #fff;
  text-transform: capitalize !important;
  text-decoration-line: none !important;
}

.deleteModal > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

.modalStyle > .ant-modal-content > .ant-modal-header > div {
  font-weight: bold !important;
}

.ant-table {
  border-radius: 5px !important;
}

.editable-row {
  font-weight: bold;
}

.ant-table-content {
  border-radius: 5px !important;
}

.firstCell {
  border-left: 5px solid #f4771b !important;
}

.orangeText {
  color: #f4771b;
}

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #feab3d;
}

.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  color: #1689ca !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #feab3d !important;
}

.ant-tabs-ink-bar {
  background: #feab3d !important;
}

.ant-table-tbody .ant-table-cell {
  background-color: #fffef6;
}

.actionItemHeader .ant-table-cell {
  background-color: #fff;
  border: 1px solid #eff7fe;
  padding: 0 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.categoryTable .ant-table-cell {
  background-color: #f9f9f9;
  border: 1px solid #eff7fe;
}

.categoryTable .ant-table-thead .ant-table-cell {
  background-color: #1271a6;
  color: #fff;
  text-transform: uppercase !important;
  text-decoration-line: none !important;
}

.categoryTable .ant-table-content {
  border-radius: 5px !important;
}

.actionItemHeader > div > div > div {
  margin-left: -8px !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

@media screen and (min-width: 744px) {
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #112538;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #112538;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-progress {
  display: block;
}

.ant-progress-outer {
  display: block;
}

.ant-progress-inner {
  display: block;
}

.ant-tabs-nav::before {
  border-bottom-color: #c0d3e7 !important;
}
.HeaderActivity {
  background-color: #05192e !important;
  border: 1px solid #2e4a61;
  border-radius: 4px;
  width: auto !important;
  min-height: 150px !important;
  max-height: 270px !important;
  scroll-behavior: smooth;
}

.HeaderActivity
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #13273c !important;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.HeaderActivity .ant-select-item-option-content {
  color: #ffffff;
}
.HeaderActivity .ant-select-item.ant-select-item-group {
  background-color: #04192e !important;
}
.HeaderActivity .ant-select-item-option-selected {
  background-color: #0c2945 !important;
}
.HeaderActivity .rc-virtual-list {
  height: 100% !important;
}

.HeaderActivityMob {
  background-color: #05192e !important;
  /* background: #05192e; */
  border: 1px solid #2e4a61;
  border-radius: 4px;
  width: auto !important;
}
.parent-collapse .ant-collapse-header {
  width: 100% !important;
  background-color: #e6e8ea !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px !important;
  padding-right: 4px !important;
  padding-left: 2px !important;
  border-radius: 0 !important;
}
.parent-collapse .ant-collapse-expand-icon .anticon {
  vertical-align: 2px !important;
}

.parent-collapse .ant-collapse-expand-icon .anticon svg path {
  fill: #8c959f !important;
  stroke: 10px !important;
}

.parent-collapse {
  border: none !important;
}
.parent-collapse .ant-collapse-item .ant-collapse-content {
  border-top: 4px solid #e6e8ea !important;
}
.parent-collapse .ant-collapse-item {
  border-bottom: none !important;
}

.child-collapse .ant-collapse-header {
  width: 100% !important;
  background-color: #192d42 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px !important;
  padding-right: 4px !important;
  padding-left: 2px !important;
  border-radius: 0 !important;
}
.child-collapse .ant-collapse-expand-icon .anticon {
  vertical-align: 2px !important;
}

.child-collapse .ant-collapse-expand-icon .anticon svg path {
  fill: #d0d5d9 !important;
  stroke: 10px !important;
}

.child-collapse {
  border: none !important;
}

.child-collapse .ant-collapse-item .ant-collapse-content {
  border-top: 4px solid #0f2339 !important;
}
.child-collapse .ant-collapse-item {
  border-bottom: none !important;
}

.nested-collapse .ant-collapse-header {
  width: 100% !important;
  background-color: #0f2339 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px !important;
  padding-right: 4px !important;
  padding-left: 2px !important;
  border-radius: 0 !important;
}
.nested-collapse .ant-collapse-expand-icon .anticon {
  vertical-align: 2px !important;
}

.nested-collapse .ant-collapse-expand-icon .anticon svg path {
  fill: #d0d5d9 !important;
  stroke: 10px !important;
}

.nested-collapse {
  border: none !important;
  background-color: #0f2339 !important;
}

.child-collapse .ant-collapse-item .ant-collapse-content {
  border-top: 2px solid #0f2339 !important;
  background-color: #0f2339 !important;
  padding: 4px 2px 4px 0px !important;
}
.nested-collapse .ant-collapse-item {
  border-bottom: none !important;
}

.child-collapse > .ant-collapse-item > .ant-collapse-content-active {
  border-left: 2px solid !important;
  border-image: linear-gradient(#374758, #374758) 1;
  border-image-slice: 1 !important;
  border-top: none !important;
  margin-left: 10px !important;
}
.child-collapse > .ant-collapse-item > .ant-collapse-content-inactive {
  border-left: 2px solid !important;
  border-image: linear-gradient(#374758, #374758) 1;
  border-image-slice: 1 !important;
  border-top: none !important;
  margin-left: 10px !important;
}
.child-collapse .ant-collapse-item {
  border: none !important;
  background-color: #0f2339 !important;
}

.nested-collapse > .ant-collapse-item > .ant-collapse-content-active {
  border-left: 2px solid !important;
  border-image: linear-gradient(white, white) 1;
  border-image-slice: 1 !important;
  transform: translateX(-2px);
  border-top: none !important;
}

.nested-collapse > .ant-collapse-item-active > .ant-collapse-header {
  border-left: 2px solid !important;
  border-image: linear-gradient(white, white) 1;
  border-image-slice: 1 !important;
  transform: translateX(-2px);
  border-top: none !important;
  position: relative !important;
}

.nested-collapse > .ant-collapse-item-active > .ant-collapse-header::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 2px;
  background-color: #ffffff;
}

.nested-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  > .ant-collapse-expand-icon {
  padding-left: 18px !important;
}
.ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0;
}
.parent-collapse > .ant-collapse-item {
  /* border-bottom: 4px solid #0f2339 !important; */
  margin-bottom: 4px !important;
}
.priority_select > .ant-select-selector {
  background-color: #0f2339 !important;
  border: none !important;
}
.select_priority {
  background-color: #1a2c42 !important;
}
.ant-select-item-option-content {
  color: #586573;
}
.more_detail_select {
  background-color: #04192e !important;
  margin-left: 20px;
  border: none !important;
}

.more_detail_select > .ant-select-selector {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #418db8 !important;
  border: none !important;
}
.more_detail_select > .ant-select-dropdown {
  background-color: red !important;
}
.select_quadrant {
  border: none !important;
}

.target_popover,
.goal_popover,
.time_popover,
.delegate_time_popover,
.custom_popover {
  background-color: transparent !important;
  border: none !important;
}

.time_popover_weekly{
  left: -215px !important;
}

.target_popover .ant-popover-inner ,
.goal_popover .ant-popover-inner {
  border-radius: 4px;
  border-top: 3px solid #1263de;
  background-color: #ffffff;
}


.target_popover .ant-popover-inner-content,
.goal_popover .ant-popover-inner-content,
.time_popover .ant-popover-inner-content,
.time_popover_weekly .ant-popover-inner-content,
.delegate_time_popover .ant-popover-inner-content,
.custom_popover .ant-popover-inner-content {
  padding: 0px !important;
  overflow: hidden !important;
}

.target_popover .ant-popover-arrow {
  right: 2px !important;
  width: 18px !important;
  height: 20px !important;
}

.goal_popover.ant-popover-placement-bottomRight .ant-popover-arrow,
.goal_popover.ant-popover-placement-bottomLeft .ant-popover-arrow {
  top: -1px;
}

.time_popover.ant-popover-placement-bottomRight .ant-popover-arrow,
.time_popover.ant-popover-placement-topRight .ant-popover-arrow {
  right: 143px;
}

.time_popover_weekly.ant-popover-placement-bottomRight .ant-popover-arrow,
.time_popover_weekly.ant-popover-placement-topRight .ant-popover-arrow {
  right: 90px;
}

.delegate_time_popover.ant-popover-placement-bottomRight .ant-popover-arrow,
.delegate_time_popover.ant-popover-placement-topRight .ant-popover-arrow {
  right: 54px;
}

.calendar_popover.ant-popover-placement-bottomRight .ant-popover-arrow,
.calendar_popover.ant-popover-placement-topRight .ant-popover-arrow {
  right: 107px;
}

.leverage_popover.ant-popover-placement-bottomRight .ant-popover-arrow,
.leverage_popover.ant-popover-placement-topRight .ant-popover-arrow {
  right: 80px;
}

.type_popover.ant-popover-placement-bottomRight .ant-popover-arrow,
.type_popover.ant-popover-placement-topRight .ant-popover-arrow {
  right: initial;
  left: 8px;
}

/* Timeline setting select */

/* .timeline_select .ant-select.ant-select-single {
  border: 1px solid #394b59 !important;
  border-radius: 0px !important;
}

.timeline_select
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 79px !important;
  height: 36px !important;
  /* border-radius: 0px !important; */

/* .modal_quadrant .ant-select-selector {
  width: 79px !important;
  height: 36px !important;
  background: #192d42 !important;
  border-radius: 0px !important;
}

.modal_quadrant.ant-select.ant-select-single {
  border: 1px solid #394b59 !important;
  border-radius: 0px !important;
}
.modal_dropdown.ant-select-dropdown {
  background: #192d42 !important;
  border-radius: 0px !important;
  padding: 0.3rem;
}

.modal_dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #13273c !important;
  color: #fff !important;
}

.modal_dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
} */

/* .timezone_dropdown.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  background: #22374c;
}

.timezone_dropdown.ant-select-dropdown .ant-select-item {
  margin-bottom: 0.4rem;
}

.timezone_dropdown.ant-select-dropdown .ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.timezone_dropdown.ant-select-dropdown {
  background: #0e2338;
  border-radius: 0px;
}
.timezone_dropdown.ant-select-dropdown .ant-select-item-option-content {
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
} */
.search_select .ant-select-single {
  width: 100%;
  height: 40px;
}

.search_select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
  border-bottom: 1px solid #e5e5e5 !important;
  padding: 0 !important ;
}

.search_select .ant-select-single .ant-select-selector {
  border-radius: 0px !important;
}

/* .search_select
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: red;
} */

/* .search_select
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector {
  border-color: #e5e5e5;
  border-color: #71aaca;
  box-shadow: none;
} */

.search_select
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: #71aaca !important;
}

.mission_select.ant-select-dropdown {
  border-radius: 0px !important;
}

.mission_select.ant-select-dropdown .ant-select-item-option-content {
  color: #1b2a3d !important;
}

.apm-drawer-dropdown .ant-dropdown-menu {
  width: 140px !important;
}
.apm-drawer-dropdown.apm-drawer-dropdown--static .ant-dropdown-menu-item:not(.apm-drawer-dropdownMenu) {
  padding: 0 !important;
}
.apm-drawer-dropdownMenu {
  background-color: #fff;
}

.apm-drawer-dropdownMenu:hover .ant-dropdown-menu-item {
  background-color: #f5f5f5 !important;
}

.apm-drawer-dropdownMenu .ant-dropdown-menu-item {
  font-size: 12px;
  font-weight: 400;
  background-color: #fff !important;
}

.apm-drawer-dropdownMenuRemove .ant-dropdown-menu-item {
  color: #ff9900;
}

.custom-week-picker .ant-picker-week-panel-row td:first-child,
.custom-week-picker .ant-picker-content thead tr th:first-child {
  display: none;
}

/* react-joyride */

.react-joyride-steps {
  text-align: left;
}

.react-joyride-steps h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
  font-family: "Inter";
}

.react-joyride-steps h6 {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
}
