body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dragging {
  cursor: ew-resize;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.apexcharts-toolbar {
  display: none !important;
}
.apexcharts-text {
  display: none !important;
}

.apexcharts-legend {
  display: none !important;
}

.label > .apexcharts-gridline {
  display: none !important;
}
/* line{
  display: none !important;
} */
.apexcharts-datalabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
}
&.visx-arc {
  fill: red !important;
}
.select_time {
  margin-right: 1.5rem !important;
}
.select_time .ant-select-selector {
  width: 118px !important;
  height: 38px !important;
  background: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 0px !important;
}
.select_time .ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #171717 !important;
}

.quadrant_modal .ant-modal-content {
  width: 475px;
  height: 313px;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}
.quadrant_modal .ant-modal-body {
  padding: 0;
}

.select_modal .ant-modal-content {
  padding: 0;
}

.only_dropdown .ant-modal-content {
  padding: 0 !important;
  width: 42px !important;
}
.only_dropdown .ant-modal-body {
  padding: 0;
}

/* /////////////////////////   Quadrant Select //////////////////////////////// */

.select_quadrant.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  /* This is for the select Input and the rest are for dropdown */
  border: none !important;
  background: transparent !important;
  outline: none !important;
}

.select_quadrant.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  /* This is for the select Input and the rest are for dropdown */
  box-shadow: none !important;
}

.select_quadrant.ant-select-single.ant-select-open .ant-select-selection-item {
  /* This is for the select Input and the rest are for dropdown */
  color: #fff !important;
}

.quadrant_select.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  background: #22374c !important;
  border-radius: 4px;
}

.quadrant_select.ant-select-dropdown .ant-select-item {
  padding: 0;
  margin-bottom: 0.4rem;
}

.quadrant_select.ant-select-dropdown .ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 10px; */
}

.quadrant_select.ant-select-dropdown {
  background: #0e2338;
  border-radius: 0px;
  min-width: 40px !important;
  max-width: 44px !important;
  width: 100% !important;
  padding: 0.35rem 0.3rem;
}
.quadrant_select.ant-select-dropdown .ant-select-item-option-content {
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}
.quadrant_select .ant-select-item:hover {
  background: #22374c !important;
}
.quadrant_select
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}

/* Modal inside Quadrant select */
.select_wrapper .ant-select-selector {
  background-color: transparent !important;
  color: #fff;
  border-radius: 0;
  border-color: #1b2f44 !important;
}
.select_wrapper .ant-select-arrow svg {
  fill: #738a9ced;
  font-size: 10px;
}
.select_wrapper .ant-select-dropdown-menu-item {
  background-color: #ba1818;
}
.select_wrapper .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #ffffff;
}

.tab_wrapper > .ant-tabs {
  margin: 16px !important;
}
.tab_wrapper
  > .ant-tabs
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  > .ant-tabs-tab-btn {
  color: #6d7f8e !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #6d7f8e;
}

.tab_wrapper
  > .ant-tabs
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-active
  > .ant-tabs-tab-btn {
  color: #ffffff !important;
}

/* time zone content */

.timezone .ant-select.ant-select-single {
  width: 222px !important;
  max-width: none;
  color: #171717;
  border: 1px solid #ced0d4 !important;
  border-radius: 0px !important;
}
.timezone .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff !important;
  border: none !important;
}

.timezone .ant-select .ant-select-arrow {
  color: #738a9c;
}
.timezone .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #171717;
}
.timezone .ant-select-selection-placeholder {
  color: #738a9c;
}

.timezone_dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  background: #22374c;
}

.timezone_dropdown .ant-select-item {
  margin-bottom: 0.4rem;
}
.timezone_dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5 !important;
  border-radius: 3px;
}
.timezone_dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #dbd6d6 !important;
}

.timezone_dropdown.ant-select-dropdown .ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.timezone_dropdown.ant-select-dropdown {
  background: #fff;
  border-radius: 0px;
  padding: 0.3rem 0.4rem;
}
.timezone_dropdown .ant-select-item-option-content {
  color: #171717;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.timezone_dropdown.ant-select-item:hover {
  background-color: red !important;
}

/* Checkbox modal  */
.checkbox_group .ant-checkbox-checked > .ant-checkbox-inner {
  background-color: #fff !important;
  border: 1px solid #818ea3 !important;
  border-radius: 0;
}
.checkbox_group .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: transparent;
}

.checkbox_group .ant-checkbox .ant-checkbox-inner {
  border-radius: 0px;
  background-color: #fff;
  border: 1px solid #818ea3 !important;
  margin-bottom: 0.35rem;
}
.checkbox_group .ant-checkbox-inner {
  width: 17px !important;
  height: 17px !important;
}
.checkbox_group .ant-checkbox-input {
  width: 17px !important;
  height: 17px !important;
}

.checkbox_group .ant-checkbox-inner::after {
  margin-left: 0px !important;
}
.checkbox_group .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #818ea3;
  border-top: 0;
  border-left: 0;
}

.checkbox_group .ant-checkbox-checked::after {
  border: none !important;
}

.custom-popover .ant-popover-inner-content {
  padding: 0 !important;
  box-shadow: none !important;
}
.custom-popover .ant-popover-arrow {
  display: none !important;
}

.select_all_checkbox .ant-checkbox-checked > .ant-checkbox-inner {
  background-color: #fff !important;
  border: 1px solid #818ea3 !important;
  border-radius: 0;
}
.select_all_checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: transparent;
}

.select_all_checkbox .ant-checkbox .ant-checkbox-inner {
  border-radius: 0px;
  background-color: #fff;
  border: 1px solid #818ea3 !important;
}

.select_all_checkbox .ant-checkbox-checked::after {
  border: none !important;
}
.select_all_checkbox .ant-checkbox-inner {
  width: 17px !important;
  height: 17px !important;
}
.select_all_checkbox .ant-checkbox-input {
  width: 17px !important;
  height: 17px !important;
}

.select_all_checkbox .ant-checkbox-inner::after {
  margin-left: 0px !important;
}
.select_all_checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #818ea3;
  border-top: 0;
  border-left: 0;
}

.timeline_select .ant-select.ant-select-single {
  border: 1px solid #e5e5e5 !important;
  border-radius: 0px !important;
}
.timeline_select .ant-select-selector {
  background-color: #fff !important;
  color: #171717;
}
.timepicker .ant-popover-inner-content {
  padding: 0;
}

.timeline_select
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 79px !important;
  height: 36px !important;
  border-radius: 0px !important;
}
.custom-dropdown.ant-select-dropdown {
  background: #fff !important;
  border-radius: 0px !important;
  padding: 0.3rem;
}
.custom-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5 !important;
  color: #fff !important;
}

.custom-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e8e4e4 !important;
}

/* Time range picker All */

.day-switch.ant-switch {
  border-radius: 3px !important;
  /* border: 1.5px solid #1271a6 !important; */
  height: 28px !important;
}
.day-switch.ant-switch .ant-switch-handle {
  height: 27.5px !important;
  width: 38% !important;
  top: 0.2px !important;
  border-radius: 3px !important;

  transition: all 0.2s ease-in-out;
}
.day-switch.ant-switch .ant-switch-handle::before {
  border-radius: 2px;
  bottom: 0.2px;
  top: 0.2px;
}
.day-switch.ant-switch.ant-switch-checked {
  background: #1271a6;
  /* border: 1px solid green !important; */
}
.day-switch.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #1271a6;
}
.day-switch.ant-switch-inner {
  border-radius: 3px !important;
}
.day-switch.ant-switch .ant-switch-inner {
  padding-top: 3px;
}

.timepicker .ant-popover-arrow {
  display: none;
}

.timepicker.ant-popover-placement-bottomRight {
  padding-top: 2px !important;
}
.timepicker.ant-popover .ant-popover-inner {
  background-color: #ffffff;
  height: 220px;
  /* width: 190px; */
  box-shadow: 0px 6px 20px 0px rgba(119, 117, 117, 0.5);
  padding: 12px;
  border-radius: 0;
}
.number_input .ant-input {
  width: 36px !important;
  padding: 4px 8.5px;
  border-radius: 3px;
  color: #a3a3a3;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.number_input .disable-arrow {
  -moz-appearance: textfield;
  appearance: textfield;
}

.number_input .disable-arrow::-webkit-inner-spin-button,
.disable-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Setting modal  */
.setting_modal .ant-modal-content {
  /* width: 540px !important;
  min-height: 610px !important;
  max-height: 100% !im; */
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}
.search_select .ant-select-single {
  width: 302px;
  height: 40px;
}

.search_select .ant-select-multiple .ant-select-selection-placeholder {
  /* position: absolute;
    top: 50%; */

  left: 0px !important;
  /* transform: translateY(-50%);
    transition: all 0.3s; */
}
.search_select > .ant-select.ant-select-single {
  border: none;
  border-radius: 0 !important;
  border-bottom: 1px solid #737373 !important;
}
.search_select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-position: calc(100% - 2px) center;
}

.search_select .ant-select-single .ant-select-selector {
  border-radius: 0px;
  padding: 0 !important;
}

.search_select .ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 0 !important;
}

/* .search_select
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: red;
} */

.search_select
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector {
  /* border-color: #e5e5e5; */
  border-color: #71aaca;
  box-shadow: none;
}

.search_select
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: #e5e5e5;
}
.mission_select.ant-select-dropdown {
  border-radius: 0px !important;
}

.mission_select.ant-select-dropdown .ant-select-item-option-content {
  color: #1b2a3d !important;
}

.statment_select .ant-select-single {
  background-color: transparent;
  height: 25px !important;
}
.statment_select .ant-select-item-option-content {
  color: #1b2a3d !important;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.stat_select .ant-select-selection-placeholder {
  color: #404040 !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.stat_select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}
.statment_select.ant-select-dropdown {
  padding: 8px 4px !important;
}

.stat_select > .ant-select.ant-select-single {
  border: none;
  border-radius: 0 !important;
}
.stat_select .ant-select-selector {
  padding: 0 !important;
  width: 300px;
}
.statment_select .ant-select-item {
  font-size: 10px !important;
  white-space: nowrap !important;
  font-weight: 600 !important;
  padding-left: 0.8rem;
}

.statment_select .ant-select-item.ant-select-item-option-disabled {
  opacity: 0.5;
}

.mission_dropDown,
.ant-dropdown-menu {
  width: 203px !important;
}
.mission_dropDown,
.ant-dropdown-menu-title-content {
  display: flex;
  justify-content: space-between;
}
/* .mission_dropDown,
.ant-dropdown-menu-item .ant-dropdown-menu-item-active {
  color: red !important;
} */

/* .ant-tooltip {
  max-width: 210px !important;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 0px !important;
}

.ant-tooltip-inner {
  background: #fff !important;
  color: rgba(27, 42, 61, 0.8) !important;
  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 0px 6px 6px 6px !important;
  border-left: 3px solid #418db8 !important;
}

.ant-tooltip-arrow-content {
  display: none !important;
} */

.statment_select.ant-select-dropdown {
  padding: 0.35rem 0.3rem;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: red !important;
}
